import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

//import { ThemeProvider } from "@mui/styles";
const theme = createTheme({
  APPBARHEIGHTMD: "64px",
  APPBARHEIGHTSM: "74px",
  APPBARHEIGHTLG: "85px",
  palette: {
    primary: {
      main: "#ffffff",
      light: "#EFF2F7",
      dark: "#014A8C",
    },
    secondary: {
      light: "#DEE4EF",
      main: "#B9CEED",
    },
  },
  typography: {
    fontFamily: "niveau-grotesk",
    allVariants: {
      color: "#014A8C",
      fontWeight: "normal",
    },
    h6: {
      color: "#ffffff",
    },
    h5: {
      color: "#48759F",
    },

    button: {
      fontSize: 16,
      textTransform: "none",
    },
  },
  borders: {
    primary: "#C0C0C1",
  },
  background: {
    primary: "#ffffff",
    green: "#44B728",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#014A8C",
          "&:hover": {
            backgroundColor: "#34a0fe",
          },
          borderRadius: 30,
          paddingLeft: "5%",
          paddingRight: "5%",
          height: "2.5rem",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // "&:hover fieldset": {
          //   borderColor: "#34a0fe",
          // },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#014A8C",
        },
      },
    },
  },
});

export const APPBARHEIGHT = 85;
export const SIDE_PADDING = "7%";
export const TOP_PADDING = "3%";

const AppTheme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default AppTheme;
