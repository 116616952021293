import React from "react";
import Theme from "./app/theme/muiTheme";
import Config from "./app/index";
import { StyledEngineProvider } from "@mui/material/styles";

const App = () => {
  return (
    <Theme>
      <StyledEngineProvider injectFirst>
        <Config />
      </StyledEngineProvider>
    </Theme>
  );
};
export default App;
