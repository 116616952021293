class User {
  constructor(user) {
    this.userInfo = { ...user };
  }

  get user() {
    return this.userInfo;
  }

  get email() {
    return this.userInfo && this.userInfo.email;
  }
  get phone() {
    return this.userInfo && this.userInfo.phone;
  }

  get id() {
    return this.userInfo && this.userInfo.id;
  }

  get role() {
    return this.userInfo && this.userInfo.role;
  }
}

export default User;
